






import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import Blocklist from "@/components/Blocklist.vue"; // @ is an alias to /src

export default Vue.extend({
  name: "Home",
  components: {
    Blocklist
  },
  created() {
    const database = firebase.database();
    console.log(database);
  }
});
