













































import Vue from "vue";

export default Vue.extend({
  name: "Blocklist",
  data() {
    return {
      blocks: [
        {
          title: "One Column Text",
          image: "onecoltext.png",
          zip: "./zips/one_col_text-1.0.zip",
          documentation: "one_col_text-1.0.txt",
          currentVersion: "1.0",
          versionHistory: {
            version: {
              num: "1.0",
              zip: "./zips/one_col_text-1.0.zip"
            }
          },
          description:
            "Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Cras ultricies ligula sed magna dictum porta.",
          category: "basic",
          tags: ["one column", "static"]
        },
        {
          title: "Two Column Text",
          image: "onecoltext.png",
          zip: "./zips/one_col_text-1.0.zip",
          documentation: "one_col_text-1.0.txt",
          currentVersion: "1.0",
          versionHistory: {
            version: {
              num: "1.0",
              zip: "one_col_text-1.0.zip"
            }
          },
          description:
            "Pretium ut lacinia in, elementum id enim. Cras ultricies ligula sed magna dictum porta.",
          category: "basic",
          tags: ["two column", "static"]
        },
        {
          title: "One Column Image",
          image: "onecoltext.png",
          zip: "./zips/one_col_text-1.0.zip",
          documentation: "one_col_text-1.0.txt",
          currentVersion: "1.0",
          versionHistory: {
            version: {
              num: "1.0",
              zip: "one_col_text-1.0.zip"
            }
          },
          description:
            "Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Cras ultricies ligula.",
          category: "basic",
          tags: ["one column", "static"]
        },
        {
          title: "Marquee Title",
          image: "onecoltext.png",
          zip: "./zips/one_col_text-1.0.zip",
          documentation: "one_col_text-1.0.txt",
          currentVersion: "1.0",
          versionHistory: {
            version: {
              num: "1.0",
              zip: "one_col_text-1.0.zip"
            }
          },
          description:
            "Quisque velit nisi, pretium ut lacinia in, elementum id enim. Cras ultricies ligula sed magna dictum porta.",
          category: "basic",
          tags: ["one column", "static"]
        },
        {
          title: "Callouts",
          image: "onecoltext.png",
          zip: "./zips/one_col_text-1.0.zip",
          documentation: "one_col_text-1.0.txt",
          currentVersion: "1.0",
          versionHistory: {
            version: {
              num: "1.0",
              zip: "one_col_text-1.0.zip"
            }
          },
          description:
            "Pretium ut lacinia in, elementum id enim. Cras ultricies ligula sed magna dictum porta.",
          category: "basic",
          tags: ["one column", "static"]
        },
        {
          title: "Form Block",
          image: "onecoltext.png",
          zip: "./zips/one_col_text-1.0.zip",
          documentation: "one_col_text-1.0.txt",
          currentVersion: "1.0",
          versionHistory: {
            version: {
              num: "1.0",
              zip: "one_col_text-1.0.zip"
            }
          },
          description:
            "Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem..",
          category: "basic",
          tags: ["one column", "static"]
        },
        {
          title: "Share Block",
          image: "onecoltext.png",
          zip: "./zips/one_col_text-1.0.zip",
          documentation: "one_col_text-1.0.txt",
          currentVersion: "1.0",
          versionHistory: {
            version: {
              num: "1.0",
              zip: "one_col_text-1.0.zip"
            }
          },
          description:
            "Eget tincidunt nibh pulvinar a. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem..",
          category: "basic",
          tags: ["one column", "static"]
        },
        {
          title: "Breadcrumbs",
          image: "onecoltext.png",
          zip: "./zips/one_col_text-1.0.zip",
          documentation: "one_col_text-1.0.txt",
          currentVersion: "1.0",
          versionHistory: {
            version: {
              num: "1.0",
              zip: "one_col_text-1.0.zip"
            }
          },
          description:
            "Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem..",
          category: "basic",
          tags: ["one column", "static"]
        },
        {
          title: "Site Map",
          image: "onecoltext.png",
          zip: "./zips/one_col_text-1.0.zip",
          documentation: "one_col_text-1.0.txt",
          currentVersion: "1.0",
          versionHistory: {
            version: {
              num: "1.0",
              zip: "one_col_text-1.0.zip"
            }
          },
          description:
            "Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.",
          category: "basic",
          tags: ["one column", "static"]
        },
        {
          title: "Summary",
          image: "onecoltext.png",
          zip: "./zips/one_col_text-1.0.zip",
          documentation: "one_col_text-1.0.txt",
          currentVersion: "1.0",
          versionHistory: {
            version: {
              num: "1.0",
              zip: "one_col_text-1.0.zip"
            }
          },
          description:
            "Accumsan id imperdiet et, porttitor at sem. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.",
          category: "basic",
          tags: ["one column", "static"]
        }
      ]
    };
  },
  methods: {
    getAssetUrl(pic: string) {
      return require("../assets/" + pic);
    }
  }
});
