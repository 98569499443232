import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // = data
    authUser: null
  },
  mutations: {
    // = setting & updating the state/data
  },
  actions: {
    // = same as methods
  },
  modules: {
    // =
  },
  getters: {
    // = computed properties
  }
});
