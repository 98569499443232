import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyDVcvwuv4bQsuh9S5HbOlBlGqJtVl_kbT8",
  authDomain: "block-library.firebaseapp.com",
  databaseURL: "https://block-library.firebaseio.com",
  projectId: "block-library",
  storageBucket: "block-library.appspot.com",
  messagingSenderId: "298813278707",
  appId: "1:298813278707:web:5e824685eb95e639022a86",
  measurementId: "G-45QS1B8BYN"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
